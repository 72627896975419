import { LeafletEventHandlerFnMap } from "leaflet";
import { useMemo, useState } from "react";

type IsPopupOpenResult = [boolean, LeafletEventHandlerFnMap];

export function useIsPopupOpen(): IsPopupOpenResult {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const eventHandlers = useMemo<LeafletEventHandlerFnMap>(
    () => ({
      popupopen: () => {
        setIsPopupOpen(true);
      },
      popupclose: () => {
        setIsPopupOpen(false);
      },
    }),
    []
  );

  return [isPopupOpen, eventHandlers];
}
