export function currentUnixTime(): number {
  return dateToUnix(new Date());
}

export function unixToDate(unix: number): Date {
  return new Date(unix * 1000);
}

export function dateToUnix(date: Date): number {
  return Math.floor(date.getTime() / 1000);
}

export function formatUnixDate(value: number, format: string) {
  return formatDate(unixToDate(value), format);
}

export function formatDate(value: Date | number | string, format: string) {
  const date = new Date(value);
  const result = format.replace(/(yyyy|MM|dd|HH|mm|ss)/g, dateFormatReplacerFactory(date));
  return result;
}

const replacerFns: { [key: string]: (x: Date) => string } = {
  yyyy: (x: Date) => x.getFullYear().toString(),
  MM: (x: Date) => lpad(x.getMonth() + 1),
  dd: (x: Date) => lpad(x.getDate()),
  HH: (x: Date) => lpad(x.getHours()),
  mm: (x: Date) => lpad(x.getMinutes()),
  ss: (x: Date) => lpad(x.getSeconds()),
};

function dateFormatReplacerFactory(value: Date) {
  return function dateFormatReplacer(match: string): string {
    const replacerFn = replacerFns[match];
    if (replacerFn) {
      return replacerFn(value);
    } else {
      return match;
    }
  };
}

function lpad(value: number): string {
  return value < 10 ? "0" + value : value.toString();
}
