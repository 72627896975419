import { parkingService, vmsService } from "api";
import { ParkingSegment, Vms } from "api/models";
import { useEffect, useState } from "react";

export type MapObjectsKind = "parkingSegments" | "vmses";

interface MapObjects {
  segments: ParkingSegment[];
  vmses: Vms[];
}

type UseMapDataObjectsResult = [MapObjects];

const refreshInterval = 3000;

export function useMapDataObjects(kind: MapObjectsKind): UseMapDataObjectsResult {
  const [mapObjects, setMapObjects] = useState(getEmptyMapObjects);

  useEffect(() => {
    let timer: number | undefined = undefined;
    let discarded = false;

    refresh();

    async function refresh() {
      if (discarded) return;
      try {
        const segments = kind === "parkingSegments" ? await parkingService.getSegments() : [];
        const vmses = kind === "vmses" ? await vmsService.getVmses() : [];
        if (discarded) return;
        setMapObjects({
          segments,
          vmses,
        });
      } catch (err) {
        console.error(err);
        if (discarded) return;
        setMapObjects(getEmptyMapObjects());
      } finally {
        timer = window.setTimeout(refresh, refreshInterval);
      }
    }

    return () => {
      discarded = true;
      if (timer) {
        window.clearTimeout(timer);
      }
      setMapObjects(getEmptyMapObjects());
    };
  }, [kind]);

  return [mapObjects];
}

function getEmptyMapObjects(): MapObjects {
  return {
    segments: [],
    vmses: [],
  };
}
