import { createRoot } from "react-dom/client";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "leaflet/dist/leaflet.css";
import "./core/styles/app.scss";
import { App } from './App';


const root = createRoot(document.getElementById("root")!);
root.render(<App />);
