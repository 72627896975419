import { VmsContent, VmsContentParkingZone, VmsWorkMode } from "api/models";

interface VmsContentViewProps {
  content: VmsContent;
}

export function VmsContentView({ content }: VmsContentViewProps) {
  if (
    content.workMode === VmsWorkMode.ParkingAvailableSpaceCount ||
    content.workMode === VmsWorkMode.ParkingStaticText
  ) {
    return content.parkingZonesContent?.length ? (
      <>
        {content.parkingZonesContent.map(x => (
          <div key={x.zoneNumber}>
            {formatParkingZoneName(x)}: {x.textContent}
          </div>
        ))}
      </>
    ) : null;
  }

  if (content.workMode === VmsWorkMode.ShowStaticText && content.textContent) {
    return <div>{content.textContent}</div>;
  }

  if (content.workMode === VmsWorkMode.ShowCustomBitmap && content.bitmapBase64) {
    return (
      <div>
        <img src={`data:image/bmp;base64, ${content.bitmapBase64}`}></img>
      </div>
    );
  }

  return null;
}

function formatParkingZoneName(zone: VmsContentParkingZone): string {
  return zone.name ? zone.name : `#${zone.zoneNumber}`;
}
