import { Dispatch, SetStateAction } from "react";
import { MapObjectsKind } from "./mapDataProvider";
import "./MapControlPanel.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  IconDefinition,
  faCar,
  faChargingStation,
  faSignHanging,
  faWheelchair,
} from "@fortawesome/free-solid-svg-icons";
import { toClassNames } from "core/utils/reactHelpers";
import { ParkingPlaceType } from "api/models";

export interface MapFilters {
  objectKinds: MapObjectsKind;
  parkingPlaceType: ParkingPlaceType;
}

interface MapControlPanelProps {
  filters: MapFilters;
  onChange: Dispatch<SetStateAction<MapFilters>>;
}

interface ParkingPlaceTypeSwitch {
  icon: IconDefinition;
  placeType: ParkingPlaceType;
}

const parkingPlaceTypeSwitches: ParkingPlaceTypeSwitch[] = [
  {
    icon: faCar,
    placeType: ParkingPlaceType.Ordinary,
  },
  {
    icon: faWheelchair,
    placeType: ParkingPlaceType.ReservedForDisabled,
  },
  {
    icon: faChargingStation,
    placeType: ParkingPlaceType.ChargingStand,
  },
];

export function MapControlPanel(props: MapControlPanelProps) {
  const { filters, onChange } = props;
  const { objectKinds, parkingPlaceType } = filters;
  const isParkingSegmentsActive = objectKinds === "parkingSegments";
  const clsVms = toClassNames({
    item: true,
    active: objectKinds === "vmses",
  });

  return (
    <div className="map-control-panel">
      <div className={clsVms} onClick={() => onChange(prev => ({ ...prev, objectKinds: "vmses" }))}>
        <FontAwesomeIcon icon={faSignHanging} />
      </div>
      {parkingPlaceTypeSwitches.map(x => (
        <div
          key={x.placeType}
          className={toClassNames({ item: true, active: isParkingSegmentsActive && parkingPlaceType === x.placeType })}
          onClick={() => onChange(prev => ({ ...prev, objectKinds: "parkingSegments", parkingPlaceType: x.placeType }))}
        >
          <FontAwesomeIcon icon={x.icon} />
        </div>
      ))}
    </div>
  );
}
