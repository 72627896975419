// File generated automatically, do not modify

import axios from 'axios';
import { Vms, VmsContent } from './models';

const paths = {
  getVmses: 'api/Vms/GetVmses',
  getVmsContent: 'api/Vms/GetVmsContent',
};

function getVmses(): Promise<Vms[]> {
  return axios.request<Vms[]>({
    url: paths.getVmses,
    method: 'get',
  }).then(res => res.data);
}

interface GetVmsContentParams {
  id?: number;
}

function getVmsContent(params: GetVmsContentParams): Promise<VmsContent> {
  return axios.request<VmsContent>({
    url: paths.getVmsContent,
    method: 'get',
    params,
  }).then(res => res.data);
}

export const vmsService = {
  paths,
  getVmses,
  getVmsContent,
};
