import { ParkingPlaceType, ParkingSegment, ParkingSegmentPlaces } from "api/models";
import L from "leaflet";
import { useMemo } from "react";
import { renderToString } from "react-dom/server";
import { ParkingSegmentIcon } from "./ParkingSegmentIcon";
import { useLatLngExpr } from "../mapUtils";
import { Marker, Popup } from "react-leaflet";
import "./ParkingSegmentMarker.scss";
import { formatUnixDate } from "core/utils/dateUtils";
import { getSegmentPlacesByType } from "./parkingSegmentUtils";

interface ParkingSegmentMarkerProps {
  data: ParkingSegment;
  placeTypeToDisplay: ParkingPlaceType;
}

export function ParkingSegmentMarker({ data, placeTypeToDisplay }: ParkingSegmentMarkerProps) {
  const places = getSegmentPlacesByType(data, placeTypeToDisplay);
  const divIcon = useDivIcon(places);
  const position = useLatLngExpr(data.latitude, data.longitude);
  const available = places ? places.spacesTotal - places.spacesOccupied : undefined;

  if (!places) return null;

  return (
    <Marker position={position} icon={divIcon}>
      <Popup>
        <div>{`${data.name}: ${available} / ${places.spacesTotal}`}</div>
        <div>
          <small>{formatUnixDate(data.lastOccupancyUpdate, "yyyy-MM-dd HH:mm:ss")}</small>
        </div>
      </Popup>
    </Marker>
  );
}

function useDivIcon(places: ParkingSegmentPlaces | undefined): L.DivIcon {
  const { spacesTotal, spacesOccupied } = places || { spacesOccupied: 0, spacesTotal: 0 };

  const available = spacesTotal - spacesOccupied;

  return useMemo(() => {
    const html = renderToString(<ParkingSegmentIcon available={available} />);
    return L.divIcon({
      html,
      iconSize: [50, 25],
      iconAnchor: [25, 12],
      popupAnchor: [0, -10],
      className: "parking-segment-marker",
    });
  }, [available]);
}
