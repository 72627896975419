import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./VmsIcon.scss";
import { faSignHanging } from "@fortawesome/free-solid-svg-icons";

interface VmsIconProps {}

export function VmsIcon(props: VmsIconProps) {
  return (
    <div className="vms-icon">
      <FontAwesomeIcon icon={faSignHanging} />
    </div>
  );
}
