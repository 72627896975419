import { Vms, VmsContent } from "api/models";
import { formatUnixDate } from "core/utils/dateUtils";
import { VmsContentView } from "./VmsContentView";

interface VmsMarkerPopupContentProps {
  vms: Vms;
  vmsContent?: VmsContent;
}

export function VmsMarkerPopupContent({ vms, vmsContent }: VmsMarkerPopupContentProps) {
  return (
    <div>
      <div>{vms.name}</div>
      {vmsContent && <VmsContentView content={vmsContent} />}
      <div>
        <small>{formatUnixDate(vms.lastCommunication, "yyyy-MM-dd HH:mm:ss")}</small>
      </div>
    </div>
  );
}
