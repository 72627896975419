import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./ParkingSegmentIcon.scss";
import { faSquareParking } from "@fortawesome/free-solid-svg-icons";

interface ParkingSegmentIconProps {
  available: number;
}

export function ParkingSegmentIcon(props: ParkingSegmentIconProps) {
  const { available } = props;

  return (
    <div className="parking-segment-icon">
      <span className="parking-symbol">
        <FontAwesomeIcon icon={faSquareParking} />
      </span>
      <span className="available-spaces">{available}</span>
    </div>
  );
}
