import { toClassNames } from "core/utils/reactHelpers";
import "./MapView.scss";
import { MapContainer, Marker, TileLayer } from "react-leaflet";
import { useMapDataObjects } from "./mapDataProvider";
import { ParkingSegmentMarker } from "./parkings/ParkingSegmentMarker";
import { VmsMarker } from "./vmses/VmsMarker";
import { MapControlPanel, MapFilters } from "./MapControlPanel";
import { useState } from "react";
import { ParkingPlaceType } from "api/models";

const defaultCenter: L.LatLngExpression = [56.035, 9.93];
const defaultZoom = 14.5;
const maxZoom = 19;
const minZoom = 14;

export function MapView() {
  const [filters, setFilters] = useState<MapFilters>({
    objectKinds: "parkingSegments",
    parkingPlaceType: ParkingPlaceType.Ordinary,
  });
  const [mapObjects] = useMapDataObjects(filters.objectKinds);
  const { segments, vmses } = mapObjects;

  const cls = toClassNames({
    "map-view": true,
    "select-mode": false, // TODO
  });

  return (
    <div className={cls}>
      <MapContainer
        dragging
        zoomDelta={0.25}
        zoomSnap={0}
        maxZoom={maxZoom}
        minZoom={minZoom}
        center={defaultCenter}
        zoom={defaultZoom}
      >
        <TileLayer
          attribution={'&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'}
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          maxNativeZoom={18}
          maxZoom={19}
        />
        {segments.map(x => (
          <ParkingSegmentMarker key={x.id} data={x} placeTypeToDisplay={filters.parkingPlaceType} />
        ))}
        {vmses.map(x => (
          <VmsMarker key={x.id} data={x} />
        ))}
      </MapContainer>
      <MapControlPanel filters={filters} onChange={setFilters} />
    </div>
  );
}
