import { MapView } from "domain/map/MapView";
import "./App.scss";

export function App() {
  return (
    <div id="app">
      <MapView />
    </div>
  );
}
