import { ParkingPlaceType, ParkingSegment, ParkingSegmentPlaces } from "api/models";

export function getSegmentPlacesByType(
  segment: ParkingSegment,
  placeType: ParkingPlaceType
): ParkingSegmentPlaces | undefined {
  switch (placeType) {
    case ParkingPlaceType.Ordinary:
      return segment.placesOrdinary;
    case ParkingPlaceType.ReservedForDisabled:
      return segment.placesDisabledPerson;
    case ParkingPlaceType.ChargingStand:
      return segment.placesChargingStand;
    default:
      return undefined;
  }
}
