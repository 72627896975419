// File generated automatically, do not modify

import axios from 'axios';
import { ParkingSegment, ParkingSensor } from './models';

const paths = {
  getSegments: 'api/Parking/GetSegments',
  getSensors: 'api/Parking/GetSensors',
};

function getSegments(): Promise<ParkingSegment[]> {
  return axios.request<ParkingSegment[]>({
    url: paths.getSegments,
    method: 'get',
  }).then(res => res.data);
}

function getSensors(): Promise<ParkingSensor[]> {
  return axios.request<ParkingSensor[]>({
    url: paths.getSensors,
    method: 'get',
  }).then(res => res.data);
}

export const parkingService = {
  paths,
  getSegments,
  getSensors,
};
