// File generated automatically, do not modify

export enum ParkingPlaceType {
  Ordinary = 0,
  ReservedForDisabled = 10,
  ChargingStand = 20,
  Private = 30,
}

export interface ParkingSegment {
  id: number;
  externalId: string;
  name?: string;
  latitude: number;
  longitude: number;
  placesOrdinary?: ParkingSegmentPlaces;
  placesDisabledPerson?: ParkingSegmentPlaces;
  placesChargingStand?: ParkingSegmentPlaces;
  lastOccupancyUpdate: number;
}

export interface ParkingSegmentPlaces {
  type: ParkingPlaceType;
  spacesOccupied: number;
  spacesTotal: number;
}

export interface ParkingSensor {
  id: number;
  externalId: string;
  name?: string;
  latitude: number;
  longitude: number;
  occupancyState: ParkingSensorOccupancyState;
  errorState: ParkingSensorErrorState;
  lastOccupancyUpdate: number;
}

export enum ParkingSensorErrorState {
  NoErrors = 0,
  Down = 1,
  Unknown = 2,
  NoDevice = 3,
}

export enum ParkingSensorOccupancyState {
  Available = 0,
  Occupied = 1,
  Unknown = -1,
}

export interface Vms {
  id: number;
  name?: string;
  latitude: number;
  longitude: number;
  syncStatus: VmsSyncStatus;
  workMode: VmsWorkMode;
  lastContentChange: number;
  lastCommunication: number;
}

export interface VmsContent {
  id: number;
  name?: string;
  latitude: number;
  longitude: number;
  syncStatus: VmsSyncStatus;
  workMode: VmsWorkMode;
  lastContentChange: number;
  lastCommunication: number;
  parkingZonesContent?: VmsContentParkingZone[];
  bitmapBase64?: string;
  textContent?: string;
}

export interface VmsContentParkingZone {
  zoneNumber: number;
  name?: string;
  textContent: string;
}

export enum VmsSyncStatus {
  NotRelated = 0,
  SyncDisabled = 1,
  Synced = 10,
  WaitingForSync = 20,
  SyncError = 30,
}

export enum VmsWorkMode {
  Detached = 0,
  ParkingAvailableSpaceCount = 10,
  ParkingStaticText = 11,
  ShowStaticText = 20,
  ShowCustomBitmap = 21,
  BlackScreen = 30,
  PowerOff = 40,
}
