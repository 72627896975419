import { Vms, VmsContent } from "api/models";
import L, { Popup as LeafletPopup } from "leaflet";
import { useEffect, useMemo, useRef, useState } from "react";
import { renderToString } from "react-dom/server";
import { VmsIcon } from "./VmsIcon";
import { useLatLngExpr } from "../mapUtils";
import { Marker, Popup } from "react-leaflet";
import { VmsMarkerPopupContent } from "./VmsMarkerPopupContent";
import { useIsPopupOpen } from "core/utils/leafletUtils";
import { vmsService } from "api";

interface VmsMarkerProps {
  data: Vms;
}

export function VmsMarker({ data }: VmsMarkerProps) {
  const divIcon = useDivIcon(data);
  const position = useLatLngExpr(data.latitude, data.longitude);
  const [isPopupOpen, popupOpenEventHandlers] = useIsPopupOpen();
  const popupRef = useRef<LeafletPopup>(null);
  const vmsContent = useVmsContentLazyLoadedData(data, isPopupOpen);

  useEffect(() => {
    window.setTimeout(() => {
      popupRef.current && popupRef.current.update();
    });
  }, [vmsContent]);

  return (
    <Marker position={position} icon={divIcon} eventHandlers={popupOpenEventHandlers}>
      <Popup ref={popupRef}>
        <VmsMarkerPopupContent vms={data} vmsContent={vmsContent} />
      </Popup>
    </Marker>
  );
}

function useDivIcon(data: Vms): L.DivIcon {
  return useMemo(() => {
    const html = renderToString(<VmsIcon />);
    return L.divIcon({
      html,
      iconSize: [25, 25],
      iconAnchor: [0, 25],
      popupAnchor: [13, -20],
      className: "vms-marker",
    });
  }, []);
}

function useVmsContentLazyLoadedData(vms: Vms, isPopupVisible: boolean): VmsContent | undefined {
  const [vmsContent, setVmsContent] = useState<VmsContent>();
  const vmsId = vms.id;
  const vmsLastContentChange = vms.lastContentChange;

  useEffect(() => {
    if (!isPopupVisible) {
      setVmsContent(undefined);
      return;
    }

    let effectDiscarded = false;
    vmsService.getVmsContent({ id: vmsId }).then(res => {
      if (effectDiscarded) return;

      setVmsContent(res);
    });

    return () => {
      effectDiscarded = true;
    };
  }, [vmsId, vmsLastContentChange, isPopupVisible]);

  return vmsContent;
}
